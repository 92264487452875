.app{
  height: 100vh;
}
.flex{
  display: flex;
}
.column{
  flex-direction: column;
}
.row{
  flex-direction: row;
}
.centered{
  justify-content: center;
  align-items: center;
}
